import React from "react";
import Header from "../Header/Header";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import "../TermsCondition/TermsCondition.css";
import "../assets/fonst/fonts.css";

export default function TermsCondition() {
  return (
    <div>
      <Header />
      <Container style={{ fontFamily: "Caros" }} className="containt">
        <div className="TermsCondition">
          <h3 className="border-bottom pb-2 text-center mb-3">
            Terms and Conditions
          </h3>
          <div>
            <h5 className="py-1">Effective Date: ___01_ day of ___May_ 2024</h5>
            <h6 className="py-1">
              The{" "}
              <b>
                ASTROHOUZ (‘the app or site”) and its website –{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="https://www.astrohouz.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.astrohouz.com ,
                </a>{" "}
              </b>{" "}
              is owned and operated by <b>HOUZ OF MEDIA LLC,</b> carrying on its
              business activities in <b>California, USA.</b> These Terms of Use
              govern your use of this application.
            </h6>
          </div>

          <div className="mt-3">
            <h5>01. WHAT INFORMATION WE COLLECT</h5>
            <div className="leftDiv">
              <p>
                These Terms of Use hereinafter referred to as “TOU” constitute a
                legally binding agreement made between you, whether personally
                or on behalf of an entity (“you, or user”) and{" "}
                <b>HOUZ OF MEDIA LLC</b>(<b>“ASTROHOUZ</b> , we,” “us” or
                “our”), concerning your access to and use of our services via
                our website –{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="https://www.astrohouz.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.astrohouz.com ,
                </a>{" "}
                and our Application (IOS and ANDROID inclusive), and any other
                media form, media channel, related, linked, or otherwise
                connected thereto (collectively, the “Site, “App” or
                ASTROHOUZ”). You agree that by accessing the Site, you have
                read, understood, and agree to be bound by all of these{" "}
                <b>TOU </b>
                Use. {" "}
                <b>
                  {" "}
                  IF YOU DO NOT AGREE WITH ALL OF THESE TOU, THEN YOU ARE
                  EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
                  DISCONTINUE USE MMEDIATELY.
                </b>
              </p>
              <p>
                <b>
                  BY SIGNING UP TO USE THE APP OR SERVICE, YOU ACKNOWLEDGE AND
                  AGREE THAT THE FORMS WE SEND YOU ARE PROPER NOTIFICATION OF
                  SUCH REPORTS AND THAT YOUR NAME AND DATE OF SENT REPORT WILL
                  BE RECORDED ON OUR DATABASE WHICH WILL SUFFICE AS A SIGNATURE
                  FOR SUCH REPORTS.
                </b>
              </p>
              <p>
                Supplemental TOU or documents that may be posted on the Site
                from time to time are hereby expressly incorporated herein by
                reference. We reserve the right, in our sole discretion, to make
                changes or modifications to these TOU at any time and for any
                reason.  We will alert you about any changes by updating the
                “Last updated” date of these TOU and you waive any right to
                receive specific notice of each such change.  It is your
                responsibility to periodically review these TOU to stay informed
                of updates.  You will be subject to and will be deemed to have
                been made aware of and to have accepted, the changes in any
                revised TOU by your continued use of the Site after the date
                such revised Terms are posted.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>02. YOUR ACCEPTANCE OF OUR PRIVACY POLICY</h5>
            <div className="leftDiv">
              <p>
                By agreeing to these TOU, you agree to the terms of our Privacy
                Policy which is expressly incorporated herein. Before using this
                Site, please carefully review our Privacy Policy. All
                information provided to us as a result of your use of this Site
                will be handled in accordance with our Privacy Policy. To the
                extent there are inconsistencies between these Terms of Use and
                our Privacy Policy, these Terms of Use control.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>03. YOUR CONSENT TO OTHER AGREEMENTS</h5>
            <div className="leftDiv">
              <p>
                When you intend to use a special feature of this Site, you may
                be asked to agree to special terms governing your use of the
                special feature. In such cases, you may be asked to expressly
                consent to the special terms, for example, by checking a box or
                clicking on a button marked “I agree.” This type of agreement is
                known as a “click-through” agreement. If any of the terms of the
                click-through agreement are different than the terms of these
                TOU, the terms of the click-through agreement will supplement or
                amend these Terms of Use, but only with respect to the matters
                governed by the “click-through agreement.”
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>04. ABOUT ASTROHOUZ</h5>
            <div className="leftDiv">
              <p>
                At <b>ASTROHOUZ</b> , we connect you with experienced
                specialists in astrology and spirituality to help navigate
                life's challenges. From personalized advice on career,
                relationships, health, and finance through calls, chats, and
                detailed reports, to bespoke ritual services conducted both
                online and, in your home, our platform is designed to empower
                and enlighten.
              </p>
              <p>
                ASTROHOUZ stands apart from the crowd with our comprehensive
                range of services:
                <ul>
                  <li>
                    <b>Call and Chat Services :</b> Engage in real-time
                    conversations with our specialists through phone or chat,
                    ensuring you receive guidance when you need it most.
                  </li>
                  <li>
                    <b>Report Services :</b> Receive detailed, personalized
                    reports that provide in-depth insights into various aspects
                    of your life, crafted carefully by our experts.
                  </li>
                  <li>
                    <b>Puja Services :</b> Experience the divine with
                    personalized puja (rituals) conducted by qualified
                    specialists, available both online and at your home.
                  </li>
                </ul>
              </p>
              <p>
                These services are designed to be accessible and convenient,
                ensuring that no matter where you are, you can easily connect
                with experienced specialists ready to assist you.
              </p>
              <p>
                We designed the site to make using the service as simple as
                possible. The site can be accessible by visiting our website or
                through our mobile application. If you choose to use the
                application via your smartphone, we recommend that you download
                the software from the <b> IOS PLAYSTORE</b> and{" "}
                <b> GOOGLE PLAYSTORE.</b> Check that your smartphone is up to
                date and that it is compatible with the most recent version of
                ASTROHOUZ
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>05. ABOUT ASTROHOUZ</h5>
            <div className="leftDiv">
              <ul>
                <div>
                  <li>
                    <b>RULES OF SERVICE</b>
                  </li>{" "}
                  <p>
                    Users may engage with specialists through <b>ASTROHOUZ</b>{" "}
                    by utilizing the interactive features provided on our
                    platform, including call, chat, and personal appointment
                    settings for ritual services. Access to these services
                    requires the creation and maintenance of an active user
                    account in accordance with the terms outlined in our User
                    Account and Responsibilities section.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Scheduling and Booking Services</b>
                  </li>{" "}
                  <p>
                    To schedule a session with one of our specialists, whether
                    for a virtual consultation via call or chat or for an
                    in-home puja service, you must follow the booking procedures
                    specified on our website or through our application. This
                    process involves selecting the specific type of service,
                    choosing a preferred specialist, and confirming the session
                    time and date. All bookings are subject to the availability
                    of the specialists and will be confirmed via our platform's
                    notification system.
                  </p>
                  <p>
                    Specialists are explicitly prohibited from exchanging direct
                    phone numbers, email addresses, or any other personal
                    information with customers. They are also forbidden from
                    enticing customers to engage in direct business outside the
                    platform, and any violation of this rule may result in the
                    specialist being permanently banned from the platform.
                  </p>
                </div>

                <div>
                  <li>
                    <b> Service Specific Terms</b>
                  </li>
                  <p>
                    Each type of service offered by <b>ASTROHOUZ</b> may be
                    governed by additional terms which will be made available at
                    the time of booking. These terms may cover specifics such as
                    the duration of consultations, the scope of the services
                    provided, cancellation policies, and any preparatory
                    requirements that must be met by you prior to the session.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Financial Transactions and Fees</b>
                  </li>
                  <p>
                    Upon booking a service, you will be required to make payment
                    through the integrated payment systems as detailed in our
                    Payments and Billing section. All fees associated with the
                    service must be paid in advance to secure your appointment.
                    The fees are determined based on the type of service
                    selected and the qualifications of the specialist engaged.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Cancellation and Rescheduling</b>
                  </li>
                  <p>
                    Users are afforded the opportunity to cancel or reschedule
                    an appointment in accordance with our cancellation policy.
                    To cancel or reschedule an appointment, you must notify
                    ASTROHOUZ before the specialist confirms your order, post
                    confirmation no cancellation is allowed. Failure to provide
                    timely notice may result in the forfeiture of any fees paid
                    for the appointment. ASTROHOUZ reserves the right to levy a
                    cancellation fee if cancellations become frequent or fail to
                    meet the required notice period.
                  </p>
                </div>

                <div>
                  <li>
                    <b>User Conduct During Engagements</b>
                  </li>
                  <p>
                    During interactions with specialists, users are expected to
                    conduct themselves in a manner that is respectful and
                    conducive to a productive consultation. You agree not to
                    engage in any abusive, offensive, or disruptive behavior.
                    Violation of this policy may lead to termination of your
                    session and possible suspension or termination of your
                    account, depending on the severity of the violation.
                  </p>
                  <p>
                    Sharing sexual content in the chat by either party will
                    result in the user being blocked and banned from the
                    platform permanently. 
                  </p>
                  <p>
                    Specialists should not imply that any statement they make is
                    endorsed by AstroHouz. Sharing unlawful, harassing,
                    privacy-invading, abusive, racist, harmful or objectional
                    material or content in the chat is strictly prohibited.
                  </p>
                </div>

                <div>
                  <li>
                    {" "}
                    <b>Confidentiality and Privacy</b>
                  </li>
                  <p>
                    All communications between you and the specialists through
                    <b>ASTROHOUZ</b> are subject to our Privacy Policy. You are
                    assured of the confidentiality and privacy of the sessions,
                    and the same expectation extends to your interactions with
                    the specialists. Specialists are bound by strict
                    confidentiality agreements and ethical standards to protect
                    your privacy and personal information.
                  </p>
                  <p>
                    Users are not allowed to request passwords, account
                    information, or any card details from each other. Direct
                    payments to specialists outside the platform are prohibited;
                    all transactions must be conducted through the platform.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Feedback and Dispute Resolution</b>
                  </li>
                  <p>
                    Following the completion of a service, you are encouraged to
                    provide feedback regarding your experience with the
                    specialist. This feedback is crucial for maintaining the
                    quality of services offered on ASTROHOUZ and for the
                    continuous improvement of our specialists. In the event of a
                    dispute or complaint about a service or a specialist, please
                    contact our customer support team as outlined in our Contact
                    Information section. We are committed to resolving any
                    issues promptly and fairly.
                  </p>
                  <p>
                    The comments you make are visible to other users and cannot
                    be altered once submitted, becoming a permanent record for
                    the specialists.
                  </p>
                  <p>
                    It's essential to be aware that you may be held legally
                    accountable for damages to someone's reputation if your
                    remarks are deemed defamatory by a court or authorized
                    agencies. Therefore, we encourage you to choose your words
                    carefully and, if you have any concerns, report them to the
                    platform before including them in the rating.
                  </p>
                  <p>
                    Under the Communications Decency Act, AstroHouz is not
                    legally responsible for any comments, even if they are
                    defamatory. 
                  </p>
                  <p>
                    To prevent the abuse of the rating system, AstroHouz
                    reserves the right to remove any comments intentionally
                    defaming the platform or made out of rivalry, any comments
                    containing vulgar or slang abuses such as "fake" or "sucks,"
                    any comments revealing personal information about the
                    customer, any comments on services beyond the platform's
                    scope, any comments that are harassing, abusive,
                    threatening, or irrelevant to the platform.
                  </p>
                  <p>
                    Comments based on age, gender, religion, race, sexual
                    orientation, disability, or ethnicity are strictly
                    prohibited.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Legal Compliance</b>
                  </li>
                  <p>
                    Engagements with specialists through ASTROHOUZ are subject
                    to compliance with all applicable local, state, national,
                    and international laws and regulations. You agree not to use
                    the services for any purpose that is prohibited by these
                    Terms and Conditions or by law.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Refusal Of Services</b>
                  </li>
                  <p>
                    We reserve the right to decline providing services to any
                    individual for any reason. Additionally, we reserve the
                    right to take legal action against anyone who engages in
                    fraudulent activities or violates applicable laws on our
                    website and app or while using any of our services.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Uploading photo</b>
                  </li>
                  <p>
                    We encourage users (customers and specialists) to upload
                    their photos on our website to put a face to the name. By
                    doing so, the user asserts ownership of the submitted
                    content or confirm that they have obtained necessary
                    permissions for its use. If users (customers and
                    specialists) choose not to upload their photo, we reserve
                    the right to use a default image at our sole discretion.
                    Submitted photo should align with the website's purpose and
                    adhere to community standards. They must not infringe on
                    copyrights, trademarks, patent, trade secret, or any
                    intellectual property rights of third parties. Photos that
                    are explicit, offensive, harassing, tortuous, defamatory,
                    infringing, vulgar, obscene, hateful, racially
                    objectionable, ethnically objectionable, or invasive of
                    another’s privacy will not be accepted. The responsibility
                    for all posted photos lies solely with the person from whom
                    the photo originated. AstroHouz retains the right to remove
                    any photo or image that violates our terms of use. Users
                    (customers and specialists) agree that their photo will be
                    used for the purpose described here.
                  </p>
                </div>
              </ul>
              <p>
                By utilizing the services offered by <b>ASTROHOUZ</b> , you
                agree to adhere to these terms for engaging specialists. These
                guidelines are designed to ensure that both users and
                specialists have a clear understanding of the mutual
                expectations and to foster a professional and respectful
                environment. Should you have any questions or require
                clarification on any aspect of engaging specialists through
                ASTROHOUZ, please do not hesitate to contact us.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>06. USE OF SERVICES/USER PROHIBITIONS</h5>
            <div className="leftDiv">
              <p>
                <b> 6.1 </b>Subject to your compliance with these Terms,{" "}
                <b>Houz of Media LLC</b> grants you a limited, non-exclusive,
                revocable (with or without cause), non-transferable right and
                license to use the Services.
              </p>

              <p>
                <b> 6.2 </b> You shall use the Services in accordance with these
                Terms and shall not:
                <ul>
                  <li>
                    Upload any Content (as defined below) that violates or
                    infringes another party’s rights of publicity, Privacy,
                    Copyright, trademark, or any other intellectual property
                    right.
                  </li>
                  <li>
                    Copy, decompile, reverse engineer, disassemble, attempt to
                    derive the source code of, decrypt, interfere with, or
                    disrupt the integrity or the performance of the services.
                  </li>
                  <li>
                    Make any modification, adaptation, improvement, enhancement,
                    translation, or derivative work from the services.
                  </li>
                  <li>
                    Violate any applicable laws, rules, or regulations in
                    connection with your access or use of the Services.
                  </li>
                  <li>
                    Use the Services in violation of or to circumvent any
                    sanctions or embargo.
                  </li>
                  <li>
                    Remove, alter, or obscure any proprietary notice (including
                    any notice of copyright and trademark) of Houz of Media LLC
                    or its affiliates, Partners, Suppliers or Licensors.
                  </li>
                  <li>
                    Use the Services for any purpose for which it is not
                    designed or intended. You also agree not to use the platform
                    to store or record data, and you release us from any
                    liability relating to data or content loss.
                  </li>
                  <li>
                    Use the Services to create or promote a product, service or
                    software that is, directly or indirectly, competitive with
                    or in any way a substitute for the Services or any services,
                    product or software offered by Houz of Media LLC.
                  </li>
                  <li>
                    Use any proprietary information or interfaces of{" "}
                    <b>Houz of Media LLC</b> or any other intellectual property
                    of Houz of Media LLC in the design, development,
                    manufacture, licensing or distribution of any application,
                    accessories, or devices for use with the Services.
                  </li>
                  <li>
                    Use the Services to send, post, or otherwise communicate any
                    Content which is offensive, indecent, threatening, abusive,
                    insulting, harassing, defamatory, libellous, deceptive,
                    fraudulent, tortious, obscene, profane, invasive of another
                    person’s privacy, or racially, ethnically, or otherwise
                    objectionable.
                  </li>
                  <li>
                    Use the Services to send automated, unsolicited, or
                    unauthorised messages, advertising or promotional material
                    or any junk mail, spam, or chain letters.
                  </li>
                  <li>
                    Upload to, or transmit through the Services any data, file,
                    software, or link that contains or redirects to a virus,
                    Trojan horse, worm, or other harmful components.
                  </li>
                  <li>
                    Use any scraper, robot, bot, spider, crawler, or any other
                    automated device or means to access, acquire, copy, or
                    monitor any portion of the Services, or any data or content
                    found or access through the Services.
                  </li>
                  <li>
                    Collect any information in respect of other users without
                    their consent.
                  </li>
                  <li>
                    Commit any act to avoid paying any applicable fees and/or
                    charges.
                  </li>
                  <li>
                    Attempt to and/or engage in any activity or act that is
                    intended to abuse, abuses, or inappropriately manipulates
                    any promotion, campaign and/or discount codes offered
                    through the Services. Such act and activities include but
                    are not limited to creating fake or duplicate accounts;
                    generating fake orders; buying and reselling your own
                    inventory.
                  </li>
                  <li>
                    Authorise or encourage anyone to do any of the foregoing.
                  </li>
                  <li>
                    Employ any audio recording or storage devices/technology to
                    create recordings, copies, scans, or transcriptions of any
                    services conducted via call, chat, or video without
                    obtaining prior written consent from the Company.
                  </li>
                </ul>
              </p>

              <p>
                <b> 6.3 </b> The intellectual property rights in all materials
                and content comprising the Service, including but not limited to
                images, written content, and designs on each page of the
                ASTROHOUZ and website, either belong to us or we have permission
                from the owner to use them to provide the Service. All such
                intellectual property is protected by worldwide intellectual
                property laws, including copyright and design laws. We give you
                permission to use the materials and content comprising the
                Service for the sole purpose of using the service in accordance
                with these terms of service.
              </p>

              <p>
                <b> 6.4 </b> Your right to use the Service is personal to you
                and you are not allowed to give this right to another person or
                to sell, gift or transfer your Account to another person. Your
                right to use the Service does not stop us from giving other
                people the right to use the service.
              </p>

              <p>
                <b> 6.5 </b> Other than as allowed in these Terms of Service or
                by us in writing, you are not given the right to use the “Houz
                of Media LLC” name, or any of the “Houz of Media LLC”
                trademarks, logos, domain names and other distinctive brand
                features, all of which are intellectual property rights that
                belong to Houz of Media LLC
              </p>

              <p>
                <b> 6.6 </b>These Terms of Service do not grant you any rights
                to, or in, any such intellectual property rights or any other
                rights or licences in respect of Houz of Media LLC’s materials
                and content, the Service, Houz of Media LLC name and/or
                trademarks, other than as set out in these Terms of Service.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>07. USER ACCOUNTS</h5>
            <div className="leftDiv">
              <ul>
                <li>
                  <b>Account Creation and Eligibility</b>
                  <p>
                    To access and utilize the Services provided by{" "}
                    <b>ASTROHOUZ</b>, users must create an account. By creating
                    an account, you confirm that you are at least 18 years of
                    age or, if higher, the age of majority in your jurisdiction,
                    and that you possess the legal authority to enter into these
                    Terms. You are responsible for providing accurate, current,
                    and complete information during the registration process and
                    for updating such information to keep it accurate, current,
                    and complete.
                  </p>
                </li>

                <li>
                  <b>Account Security</b>
                  <p>
                    Upon registering, you will be asked to choose a password.
                    You are responsible for safeguarding the password you use to
                    access the Services and for any activities or actions under
                    your password. <b>ASTROHOUZ</b> encourages the use of
                    "strong" passwords (passwords that use a combination of
                    upper- and lower-case letters, numbers, and symbols) with
                    your account. You agree to notify <b>ASTROHOUZ</b>{" "}
                    immediately of any unauthorized use of your account or any
                    other breach of security.
                  </p>
                </li>

                <li>
                  <b>Account Responsibilities</b>
                  <p>
                    You are solely responsible for all activities that occur
                    under your account, whether or not you know about them.
                    <b>ASTROHOUZ</b> will not be liable for any loss or damage
                    arising from your failure to comply with the above
                    requirements. Furthermore, you may not use as a username the
                    name of another person or entity or that is not lawfully
                    available for use, a name or trademark that is subject to
                    any rights of another person or entity other than you
                    without appropriate authorization, or a name that is
                    otherwise offensive, vulgar or obscene.
                  </p>
                </li>

                <li>
                  <b>User Verification</b>
                  <p>
                    Your account must be verified using a valid email address.
                    You may also be required to verify your account via a
                    verification code sent to your registered email address
                    every time you log in, or by linking to a Google account or
                    Apple ID as an additional method of verification. ASTROHOUZ
                    reserves the right to take steps to verify the accuracy of
                    the information you provide, including but not limited to
                    your identity, credentials, and qualifications related to
                    our services.
                  </p>
                </li>

                <li>
                  <b>Account Modifications and Termination</b>
                  <p>
                    You may modify your account information or deactivate your
                    account at any time through the user interface on the
                    website or by contacting customer support directly.
                    <b>ASTROHOUZ</b> reserves the right to terminate your
                    account if any information provided during the registration
                    process or thereafter proves to be inaccurate, not current,
                    or incomplete. Additionally, <b>ASTROHOUZ</b> reserves the
                    right to terminate or suspend your account if activities
                    occur on your account that constitute or may constitute a
                    violation of these Terms and Conditions or of any applicable
                    laws.
                  </p>
                </li>

                <li>
                  <b>Data Privacy</b>
                  <p>
                    The information provided to us in connection with the
                    registration and maintenance of your account will be used
                    and protected in accordance with our Privacy Policy. By
                    accepting these Terms, you acknowledge that you understand
                    and agree to the collection, storage, processing, and
                    sharing of your personal information as described in our
                    Privacy Policy.
                  </p>
                </li>

                <li>
                  <b>Consequences of Account Termination</b>
                  <p>
                    Upon termination of your account, you will lose all access
                    to the Services and any information stored within your
                    account. <b>ASTROHOUZ</b> reserves the right to delete any
                    content related to your account after a reasonable period
                    post-termination. It is your responsibility to secure any
                    necessary data or content prior to termination.
                  </p>
                </li>
              </ul>

              <p>
                By registering and maintaining an account with <b>ASTROHOUZ</b>{" "}
                , you agree to comply with and be bound by the terms outlined in
                this section, ensuring responsible use and management of your
                account. If you have any questions or need assistance with
                account management, please contact our support team at{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:privacy@houzofmedia.com"
                >
                  privacy@houzofmedia.com .
                </a>
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>08. ASTROHOUZ’ INTELLECTUAL PROPERTY</h5>
            <div className="leftDiv">
              <p>
                <b>Content</b> For purposes of these Terms, “content” is defined
                as any information, communications, published works, photos,
                videos, graphics, music, sounds, or other materials that can be
                viewed by users on our Site and is owned by ASTROHOUZ, our
                Affiliates or our licensors.
              </p>

              <p>
                <b>Ownership of Content</b>  All content on the Site is subject
                to intellectual property rights, contractual or other
                protection. The intellectual property rights are owned by us or
                our licensors. No content may be copied, distributed,
                republished, uploaded, posted or transmitted in any way except
                as provided expressly in these Terms or with{" "}
                <b>HOUZ OF MEDIA</b> prior express written consent. Any use of
                the content other than as permitted by these Terms, or any other
                unauthorized use of the content may make you liable to{" "}
                <b>ASTROHOUZ</b> or its licensors for violation of intellectual
                property rights.
              </p>

              <p>
                <b>Trademarks</b> or service marks of <b>ASTROHOUZ </b> include,
                but are not limited to, <b>ASTROHOUZ™</b> and the{" "}
                <b>ASTROHOUZ </b> logo. All custom graphics, icons, logos and
                service names are registered or common-law trademarks or service
                marks of <b>ASTROHOUZ </b> or our Affiliates. All other
                trademarks or service marks are property of their respective
                owners. Nothing in these Terms grants you any right to use any
                trademark, service mark, logo, and/or the name of{" "}
                <b>ASTROHOUZ,</b> our Affiliates or our licensors.
              </p>

              <p>
                <b> Site Use ASTROHOUZ</b> grants you a limited, revocable,
                nonexclusive license to use the content on the Site solely for
                your own personal purposes only and not for republication,
                distribution, assignment, sublicense, sale, preparation of
                derivative works, or other use. You may not use any content from
                the Site which is not owned by you for commercial use. You agree
                not to copy the Site or content located on the Site; to reverse
                engineer or break into the Site; or to use content, products, or
                services in violation of any law. Any use of the Site or the
                content contained therein other than as specifically authorized
                in the Agreement, without the prior written permission of{" "}
                <b>ASTROHOUZ,</b> is strictly prohibited and will terminate the
                license granted herein. Unless explicitly stated herein, nothing
                in the Agreement shall be construed as conferring to you,
                whether by implication, estoppel or otherwise, any title or
                ownership of, or exclusive use-rights to, any intellectual
                property or other right and any goodwill associated therewith.{" "}
                <b>ASTROHOUZ </b>reserves the right, without notice and in its
                sole discretion, to terminate your license to use the Site at
                any time and to block or prevent your future access to, and use
                of, the Site.
              </p>

              <p>
                <b> No Warranty for Third-Party Infringement</b> Neither we nor
                our Affiliates warrant or represent that your use of materials
                displayed on, or obtained through, this Site will not infringe
                the rights of other users of the Site or of third parties.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>09. SOFTWARE UPDATES</h5>
            <div className="leftDiv">
              <p>
                ASTROHOUZ is constantly changing and improving the Service;
                therefore, we may make modifications or changes (to all or part
                of the Service) such as adding or removing features and
                functionalities, offering new services or discontinuing old
                ones. We may also change the Service for these other reasons:
                <ul>
                  <li>to adapt to new technologies</li>
                  <li>
                    to reflect increases or decreases in the number of people
                    who use a particular product, service or feature
                  </li>
                  <li>
                    to respond to key changes in the licences and partnerships
                    we have with others
                  </li>
                  <li>to prevent abuse or harm</li>
                  <li>
                    to address legal, regulatory, safety or security issues.
                  </li>
                </ul>
              </p>

              <p>
                In particular, we sometimes make legally-required updates, which
                are modifications that keep digital content, services in
                conformity with the law. We make these updates to our digital
                content and services for safety or security reasons, and to make
                sure they meet the quality standards that you expect, such as
                those described in our Legal Guarantee below.  We may
                automatically install updates that address significant safety or
                security risks. For other updates, you can choose whether you
                want them installed.
              </p>

              <p>
                Before we change or stop offering any part of the Service, we
                carefully consider your interests as a user, your reasonable
                expectations, and the potential impact on you and others. We
                only change or stop offering any part of the Service for valid
                reasons. If a modification negatively affects your ability to
                access or use the Service, we’ll provide you with reasonable
                advance notice by email, including a description of the changes,
                when they’ll take place, and your right to end your contract
                with us if our modifications create more than a minor negative
                impact, except in urgent situations such as preventing abuse or
                harm, responding to legal requirements, or addressing security
                and operability issues.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>10. BILLING AND PAYMENTS</h5>
            <div className="leftDiv">
              <ul>
                <div>
                  <li>
                    <b>Payment Methods and Billing Information</b>
                  </li>
                  <p className="mt-1">
                    <b>ASTROHOUZ</b> accepts payments via PayPal and credit
                    cards through the Stripe payment gateway. By electing to use
                    these payment methods, you represent and warrant that you
                    have the legal right to use any credit card(s) or other
                    payment means used to initiate any transaction. You agree to
                    pay all charges incurred by users of your credit card, debit
                    card, or other payment method used in connection with a
                    purchase or transaction or other monetary transaction
                    interaction with
                    <b>ASTROHOUZ</b> at the prices in effect when such charges
                    are incurred.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Wallet Recharge</b>
                  </li>
                  <p className="mt-1">
                    For all services, ASTROHOUZ requires Users to pre-load funds
                    into a digital wallet ("Wallet") associated with your user
                    account. You acknowledge and agree that all funds deposited
                    into your Wallet are considered a pre-payment for the
                    services offered through ASTROHOUZ. Such funds will
                    immediately be credited to your Wallet upon confirmation of
                    payment. Users can then use these funds to book and pay for
                    individual services as described in the Services section of
                    our Terms and Conditions.
                  </p>
                </div>

                <div>
                  <li>
                    <b>Refunds and Cancellation Policy</b>
                    <p className="mt-1">
                      ASTROHOUZ provides a structured refund and cancellation
                      policy to ensure fairness and user satisfaction.
                      <ul className="mt-2">
                        <li>
                          <b>No Refund after Confirmation: </b>No refund will be
                          initiated once a product is booked and confirmed by
                          the specialist.
                        </li>
                        <li>
                          <b> Incorrect Information:</b> No refund for wrong
                          information entered. Customers are advised to
                          double-check the information provided. Cancel the
                          booking immediately and rebook the service.
                        </li>
                        <li>
                          <b> Wrong Phone Number:</b> No refund for entering the
                          wrong phone number for call or chat services.
                        </li>
                        <li>
                          <b>Specialist Unavailability: </b> Full refund to the
                          AstroHouz wallet if a booked specialist is unable to
                          provide the service due to unavoidable circumstances.
                          Users can rebook with another specialist using the
                          wallet balance.
                        </li>
                        <li>
                          <b>Completed Services:</b> No refund for completed
                          orders where the service has been provided.
                        </li>
                        <li>
                          <b>Results and Entertainment:</b> No refund based on
                          individual experiences as results may vary. Services
                          are for entertainment purposes and not an alternative
                          to professional consultation or treatment.
                        </li>
                        <li>
                          <b>Not a Replacement for Professional Services:</b>{" "}
                          AstroHouz services are not meant to replace treatment
                          advised by licensed professionals (e.g., doctors,
                          lawyers, therapists). Users are advised to exercise
                          discretion, and no refund is issued on these grounds.
                        </li>
                        <li>
                          <b>Refund Process: </b> Refunds, if any, will be in
                          the wallet after deducting transaction charges.
                        </li>
                        <li>
                          <b>Non-Cancellable Bookings:</b> Bookings are
                          non-cancellable. If AstroHouz cancels a booking due to
                          non-availability of the chosen specialist, the refund
                          will be in the AstroHouz wallet.
                        </li>
                        <li>
                          <b>Connectivity Issues:</b> No refund for glitches or
                          failures of your network. For services requiring
                          internet/cellular network connectivity, users must
                          remain in the proper network throughout the booked
                          date.
                        </li>
                        <li>
                          <b>Service unavailability :</b> If a booked service is
                          unavailable due to any reason, the full amount will be
                          refunded to the AstroHouz wallet.
                        </li>
                        <li>
                          Users should note that funds returned to the Wallet
                          can be used for future services on ASTROHOUZ but will
                          not be refunded back to the credit card or PayPal
                          account. This is to maintain a simplified and secure
                          transaction environment and to minimize risks
                          associated with fraudulent chargebacks and refunds.
                        </li>

                        <p>
                          This policy is comprehensive and transparent,
                          providing users with clear information on the terms
                          and conditions governing refunds and cancellations.
                          The reservation of the right to refuse or cancel any
                          order adds flexibility to the platform's operations.
                          Users are encouraged to review and understand these
                          terms before proceeding with bookings.
                        </p>
                      </ul>
                    </p>
                  </li>

                  <li>
                    <b>Responsibility for Taxes</b>
                    <p className="mt-1">
                      The reporting and payment of any applicable taxes arising
                      from the use of ASTROHOUZ Services is the responsibility
                      of the User. You acknowledge that ASTROHOUZ is not
                      obligated to determine whether taxes apply and is not
                      responsible to collect, report, or remit tax information
                      or taxes to any tax authority arising from any
                      transaction.
                    </p>
                  </li>

                  <li>
                    <b>Transaction Disputes</b>
                    <p className="mt-1">
                      You agree to notify <b>ASTROHOUZ</b> of any disputes or
                      discrepancies regarding charges for wallet recharges
                      within 30 days after the charge first appears on your
                      billing statement. If you do not bring such disputes to{" "}
                      <b>ASTROHOUZ'S</b> attention within 30 days, you agree
                      that you waive the right to dispute such charges.
                    </p>
                    <p className="mt-1">
                      Any dispute regarding wallet transactions on the platform
                      (either through the app or website) must be reported
                      within 24 hours of the transaction occurring.
                    </p>
                  </li>

                  <li>
                    <b>Security of Transactions</b>
                    <p className="mt-1">
                      <b>ASTROHOUZ</b> uses commercially reasonable efforts to
                      ensure that your financial information is handled with
                      care. The Stripe payment gateway complies with the Payment
                      Card Industry Data Security Standard <b>(PCI DSS)</b> and
                      employs various measures, including encryption, to protect
                      your financial data. However, <b>ASTROHOUZ</b> cannot
                      guarantee that unauthorized third parties will never be
                      able to defeat those measures or use your personal
                      information for improper purposes.
                    </p>
                  </li>
                </div>
                <p>
                  By using ASTROHOUZ’S Services, you agree to these payment and
                  billing terms, which enhance our ability to provide
                  high-quality services to you and ensure operational
                  effectiveness. Should you have any questions or concerns
                  regarding these policies, please contact us at
                  <a href="mailto:privacy@houzofmedia.com">
                    {" "}
                    privacy@houzofmedia.com.
                  </a>
                </p>
              </ul>
            </div>
          </div>

          <div className="mt-3">
            <h5>11. APP USE LICENSE</h5>
            <div className="leftDiv">
              <p>
                When you use the Services through the App, then <b>ASTROHOUZ</b>{" "}
                grant you a revocable, non-transferable, non-exclusive, limited
                right to install and use it on wireless phones and devices that
                are in accordance with the terms and conditions of this license,
                used and controlled by you and shall not:
                <ul>
                  <li>
                    Decompile, disassemble, reverse-engineer, attempt to know
                    the source code of the App, or decrypt it.
                  </li>
                  <li>
                    go against any applicable laws, regulations, or rules
                    regarding your use of the App.
                  </li>
                  <li>
                    alter, remove, or obscure any of <b>ASTROHOUZ’</b>{" "}
                    proprietary notice, and the licensor of the App.
                  </li>
                  <li>
                    make the App available over network or other environs that
                    allow access or use by multiple devices or multiple users at
                    the same time.
                  </li>
                  <li>
                    use the App to create services, or software that is directly
                    or indirectly competitive with or in any way a substitute
                    for the App.
                  </li>
                  <li>
                    use the App to send automated queries or unsolicited
                    commercial emails to any platform.
                  </li>
                  <li>
                    Make use of any proprietary information of <b>ASTROHOUZ</b>{" "}
                    in the development, design, manufacture, distribution, or
                    licensing of any application, devices, or accessories for
                    use with the App.
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>12. TERMS REGARDING APPLE AND ANDROID DEVICES</h5>
            <div className="leftDiv">
              <p>
                The following terms and conditions will apply when you access
                the App either from the Apple Store or the Google Play Store.
                You acknowledge that these Terms is between you and{" "}
                <b>ASTROHOUZ</b> only and not with Apple Inc. or Google Inc.
                (both an “App Distributor”), and <b>ASTROHOUZ</b> (which is not
                an App Distributor) is directly responsible for the App and the
                App Content thereof.
                <ul>
                  <li>
                    Scope of License: The license granted to you for the App is
                    limited to a non-transferable permit to use the App on a
                    device that uses the Apple iOS and/or Android operating
                    system as applicable and in line with the usage rules set
                    forth in the applicable App Distributor terms and conditions
                    agreement.
                  </li>
                  <li>
                    Maintenance and Support: <b>ASTROHOUZ</b> is solely
                    responsible for providing any maintenance and support
                    services with respect to the App as specified under these
                    Terms and applicable laws. You hereby agree that no App
                    Distributor is responsible for any support and maintenance
                    services in relation to the App.
                  </li>
                  <li>
                    Warranty: <b>ASTROHOUZ</b> is responsible for any service
                    warranties, whether express or implied by law, to the extent
                    not effectively disclaimed. In any event, where the App
                    fails to conform to any applicable warranty, you may alert
                    the relevant App Distributor, and the App Distributor, in
                    accordance with its policies and terms, may refund the
                    purchase price (if any) paid for the App, and to the fullest
                    extent permissible by applicable law, an App Distributor
                    will have no other warranty obligation whatsoever with
                    respect to the App and any other claims, losses,
                    liabilities, damages, expenses or costs attributable to any
                    failure to obey any warranty will be ASTROHOUZ’ sole
                    responsibility.
                  </li>
                  <li>
                    Service Claims: You hereby agree that <b>ASTROHOUZ</b> (not
                    an App Distributor) is liable for addressing any of your
                    claims or those of a third party relating to the App or your
                    control and/or use thereof, including but not limited to;
                    (a) service liability claims; (b) any claim that the App
                    fails to obey any applicable or legal requirement; and (iii)
                    claims arising from consumer protection or similar
                    legislation.
                  </li>
                  <li>
                    Intellectual Property Rights: You acknowledge that in the
                    event of any third-party claim that the App or your use
                    thereof infringes a third party’s intellectual property
                    rights, the relevant App Distributor will not be responsible
                    for the investigation, defense, settlement, and discharge of
                    any such intellectual property infringement claim.
                  </li>
                  <li>
                    Legal Compliance: You agree to follow all relevant rules and
                    regulations in the country from which you access the service
                    or use the application. It is also the obligation of users
                    to exercise caution while engaging with other users on the
                    platform from other places.
                  </li>
                  <li>
                    Third Party Terms and Conditions Agreement: You must comply
                    with the third-party terms and conditions agreement
                    applicable when using the App. For example, if you have a
                    VoIP application, you must not violate their wireless data
                    service agreement when using the App.
                  </li>
                  <li>
                    Third Party Beneficiary: Both you and <b>ASTROHOUZ</b>{" "}
                    acknowledge that the App Distributors and their subsidiaries
                    are third party beneficiaries of these Terms and that upon
                    your acceptance of the terms and conditions of these Terms,
                    each App Distributor will have the right to enforce these
                    Terms against you as a third-party beneficiary thereof.
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>13. TERMINATION</h5>
            <div className="leftDiv">
              <ul>
                <li>
                  <b>Termination by you.</b> You may terminate these Terms and
                  the license granted to you hereunder at any time by
                  uninstalling and removing the Software from your device, and
                  by ceasing to use the App.
                </li>
                <li>
                  <b>Termination by us.</b> Without prejudice to any other
                  rights, we may have, these Terms and the license granted to
                  you hereunder automatically terminate without notice, if you
                  fail to comply with or breach any provision of these Terms. In
                  no event will we be liable for the suspension, removal of or
                  disabling of your access to <b>ASTROHOUZ</b> or to any feature
                  available therein. You acknowledge that upon expiration or
                  termination of your license, the license key may automatically
                  de-activate.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-3">
            <h5>14. EXCLUSION OF LIABILITY FOR EXTERNAL LINKS</h5>
            <div className="leftDiv">
              <p>
                The Website may contain connections to other websites, and
                platform users may also publish external links. <b>ASTROHOUZ</b>{" "}
                hereby declares explicitly that it has no influence on the
                layout or content of the linked pages and dissociates itself
                expressly from all contents of all linked pages of third
                parties. <b>ASTROHOUZ</b> shall not be liable for the use or
                content of Internet sites that link to this site, or which are
                linked from it. Our <b>privacy</b> and <b>cookie</b> notice do
                not apply to any collection and processing of your personal data
                on or through such external sites.
                <br />
                You may terminate these Terms and the license granted to you
                hereunder at any time by uninstalling and removing the Software
                from your device, and by ceasing to use the App.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>15. YOUR REPRESENTATIONS AND WARRANTIES</h5>
            <div className="leftDiv">
              <p>You hereby represent and warrant that:</p>
              <ol type="i">
                <li>
                  you will only use <b>ASTROHOUZ</b> as permitted under these
                  Terms;
                </li>
                <li>
                  you agree to comply with all applicable laws, rules and
                  regulations, and industry best practices while using
                  <b>ASTROHOUZ</b>;
                </li>
                <li>
                  you will not use <b>ASTROHOUZ</b> for any fraudulent or
                  inappropriate purpose;
                </li>
                <li>
                  you shall not prevent others from using <b>ASTROHOUZ</b>
                </li>
              </ol>
            </div>
          </div>

          <div className="mt-3">
            <h5>16. DISCLAIMER OF WARRANTIES</h5>
            <div className="leftDiv">
              <p>
                ASTROHOUZ IS PROVIDED TO YOU “AS IS”, WITHOUT WARRANTY OF ANY
                KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE
                DISCLAIM ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND NONINFRINGEMENT AND ANY WARRANTIES AND CONDITIONS
                ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE DO NOT
                REPRESENT OR WARRANT THAT:
              </p>
              <ol type="I">
                <li>
                  ASTROHOUZ WILL MEET YOUR REQUIREMENTS, WILL BE ERROR FREE OR
                  THAT ANY ERRORS WILL BE CORRECTED;
                </li>
                <li>THE OPERATION OF ASTROHOUZ WILL BE UNINTERRUPTED; OR</li>
                <li>
                  ASTROHOUZ IS OR WILL BE AVAILABLE WHERE YOU RESIDE OR IN ANY
                  OTHER PARTICULAR LOCATION. YOUR ONLY RIGHT OR REMEDY WITH
                  RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH ASTROHOUZ IS
                  TO UNINSTALL AND CEASE USE OF ALL ASTROHOUZ PRODUCTS.
                </li>
              </ol>
              <p>
                Further and except as expressly provided herein, we are not
                obligated to maintain or support ASTROHOUZ, or to provide you
                with any updates, fix errors or any other features available
                therein. You acknowledge and agree that you are solely
                responsible for (and that we have no responsibility to you or to
                any third party) and assume all the responsibility and risk for
                your use of ASTROHOUZ and your breach of any of your
                representations and warranties herein contained, and for any
                loss or damage which we may suffer as a result of any such
                breach.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>17. LIMITATION OF LIABILITY</h5>
            <div className="leftDiv">
              <p>
                TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO
                CIRCUMSTANCES SHALL WE, OUR OFFICERS, DIRECTORS, EMPLOYEES,
                PARENTS, AFFILIATES, SUCCESSORS, ASSIGNS, OR LICENSORS BE LIABLE
                TO YOU OR ANY OTHER THIRD PARTY FOR ANY INDIRECT, SPECIAL,
                INCIDENTAL, OR CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES OF
                ANY TYPE INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
                GOODWILL, SERVICE INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION,
                LOSS OF BUSINESS PROFITS, LOSS OF DATA OR BUSINESS INFORMATION,
                LOSS OF ADDITIONAL SOFTWARE OR COMPUTER CONFIGURATIONS OR COSTS
                OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DAMAGES ARISING
                IN CONNECTION WITH ANY USE OF ASTROHOUZ OR ANY AND ALL OTHER
                COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION
                WITH THESE TERMS OR THE INSTALLATION, UNINSTALLATION, USE OF OR
                INABILITY TO USE ASTROHOUZ UNDER ANY THEORY OF LIABILITY,
                INCLUDING BUT NOT LIMITED TO CONTRACT OR TORT (INCLUDING
                PRODUCTS LIABILITY, STRICT LIABILITY AND NEGLIGENCE), AND
                WHETHER OR NOT WE WERE OR SHOULD HAVE BEEN AWARE OR ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGE AND NOTWITHSTANDING THE FAILURE
                OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY STATED HEREIN.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
                EVENT SHALL OUR TOTAL LIABILITY (INCLUDING OUR OFFICERS,
                DIRECTORS, EMPLOYEES, PARENTS, AND AFFILIATES) FOR ANY CLAIM
                ARISING OUT OF OR RELATED TO THESE TERMS, TO THE FULLEST EXTENT
                POSSIBLE UNDER APPLICABLE LAW, EXCEED THE AMOUNT PAID IF ANY, BY
                YOU FOR THE USE OF ASTROHOUZ.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>18. INDEMNITY</h5>
            <div className="leftDiv">
              <p>
                You agree to defend, indemnify and hold us, our parent
                corporation, officers, directors, employees and agents, harmless
                from and against any and all claims, damages, obligations,
                losses, liabilities, costs and expenses (including but not
                limited to attorney's fees) arising from:
              </p>
              <ol type="i">
                <li>your access to or use of ASTROHOUZ</li>
                <li>your violation of these Terms; </li>
                <li>
                  your violation of any third party right, including without
                  limitation, any intellectual property right, or privacy right.
                </li>
              </ol>
            </div>
          </div>

          <div className="mt-3">
            <h5>19. GOVERNING LAW AND DISPUTES</h5>
            <div className="leftDiv">
              <p>
                This Agreement will be governed by, construed, and enforced in
                accordance with the laws of California, without regard to its
                conflicts of law principles or provisions. California shall have
                jurisdiction to adjudicate disputes.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>20. GENERALS</h5>
            <div className="leftDiv">
              <p>
                These Terms constitutes the entire understanding between the
                parties with respect to the matters referred to herein. The
                Section headings in these Terms are provided for convenience
                purpose only and have no legal or contractual significance. If
                any provision of these Terms is held to be unenforceable by a
                court of competent jurisdiction, such provision shall be
                enforced to the maximum extent permissible so as to affect the
                intent of the parties, and the remainder of these Terms shall
                continue in full force and effect. Our failure to enforce any
                rights or to take action against you in the event of any breach
                hereunder shall not be deemed a waiver of such rights or of
                subsequent actions in the event of future breaches. These Terms
                and any right granted herein may not be assigned by you without
                our prior written consent. The controlling language of these
                Terms is English. In the event of inconsistency or discrepancy
                between the English version and any other language version, the
                English language version shall prevail. Nothing in these Terms
                will be construed as creating a joint venture, partnership,
                employment or agency relationship between you and us, and you do
                not have any authority to create any obligation or make any
                representation on our behalf.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>21. DISCLAIMER OF SERVICES</h5>
            <div className="leftDiv">
              <ul>
                <li className="mt-2">
                  <b>Introduction</b>
                  <br />
                  Welcome to <b>ASTROHOUZ </b>, a distinct brand under HOUZ OF
                  MEDIA LLC, dedicated to providing psychic and astrological
                  entertainment services. Our offerings include but are not
                  limited to live specialist sessions, astrology consultations,
                  online and in person rituals and diverse content such as
                  newsletters, blogs, horoscopes, and other related
                  publications.
                </li>
                <li className="mt-2">
                  <b>Nature of Services</b>
                  <br />
                  <b>ASTROHOUZ</b> offers specialized astrological and spiritual
                  services designed to provide guidance across various life
                  aspects, such as career, relationships, health, and finance.
                  Our services, which include real-time calls and chats with
                  experienced specialists, personalized reports, and bespoke
                  puja (ritual) services, are solely for entertainment purposes.
                  They are accessible both online and, for certain services,
                  in-person at your home, emphasizing convenience and user
                  accessibility. While these services aim to enlighten and
                  empower, they should not replace professional advice or
                  treatment from licensed professionals in critical areas of
                  life.
                </li>
                <li className="mt-2">
                  <b>Limitation of Liability</b>
                  <br />
                  The astrological and psychic consultations and any predictions
                  made by <b>ASTROHOUZ</b> are to be treated as speculative.
                  These forecasts do not carry guarantees or warranties
                  regarding their accuracy or the occurrence of predicted
                  events.
                  <b>ASTROHOUZ</b> expressly disclaims responsibility for any
                  actions taken based on the interpretation of information
                  provided through our services. Users should not use our
                  services as a substitute for advice from qualified
                  professionals in fields such as therapy, finance, medicine,
                  law, real estate, or psychiatry.
                </li>

                <li className="mt-2">
                  <b>User Eligibility and Acceptance of Terms</b>
                  <br />
                  To access and use the services offered by <b>ASTROHOUZ</b>,
                  users must be at least 18 years of age. By engaging with our
                  services, users acknowledge and agree to abide by our Terms
                  and Conditions and our Privacy Policy. Users must be at least
                  16 years of age to subscribe to our newsletters.
                </li>

                <li className="mt-2">
                  <b>Indemnification</b>
                  <br />
                  Users agree to indemnify and hold harmless <b>
                    ASTROHOUZ
                  </b>{" "}
                  from any claims, losses, damages, or other liabilities,
                  including but not limited to theft and damage, arising from
                  their use of our services or any information obtained
                  therefrom.
                </li>

                <li className="mt-2">
                  <b>Transactions and Refunds</b>
                  <br />
                  Only the registered account holder, or the cardholder, is
                  authorized to make purchases on our platform using payment
                  methods such as credit cards, debit cards, PayPal, among
                  others. Refunds, when applicable, are credited only to the
                  user’s wallet on the ASTROHOUZ platform and not returned to
                  the original payment method.
                </li>

                <li className="mt-2">
                  <b>Specialist Selection and Reliance</b>
                  <br />
                  Users are solely responsible for selecting specialists and
                  must evaluate the specialists’ credentials and appropriateness
                  for their needs. While some specialists’ credentials may be
                  verified by third-party services, ASTROHOUZ does not endorse,
                  verify, or assume responsibility for the validity of such
                  qualifications. The engagement with any specialist and
                  reliance on their advice is a matter entirely at the
                  discretion of the user.
                </li>

                <li className="mt-2">
                  <b>Third-Party Verification</b>
                  <br />
                  <b>ASTROHOUZ</b> utilizes third-party services for the
                  verification of specialists' credentials. However, ASTROHOUZ
                  does not guarantee the accuracy or reliability of such
                  verifications and makes no representations regarding the
                  qualifications or identities of specialists.
                </li>

                <li className="mt-2">
                  <b>Communication and Content Responsibility</b>
                  <br />
                  Specialists are independent contractors and not employees of
                  <b>ASTROHOUZ.</b> All communications and interactions between
                  users and specialists are the sole responsibility of the
                  participating parties. ASTROHOUZ does not monitor, control, or
                  assume liability for the content exchanged, nor do we provide
                  the content ourselves.
                </li>

                <li className="mt-2">
                  <b>Acknowledgment of Disclaimer</b>
                  <br />
                  It is imperative for all users to read, understand, and accept
                  this disclaimer in full before utilizing the services provided
                  by <b>ASTROHOUZ.</b> By accessing and using our services,
                  users explicitly acknowledge their understanding that our
                  offerings are for entertainment purposes only and agree to all
                  terms laid out herein.
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-3">
            <h5>22. CONTACT US</h5>
            <div className="leftDiv">
              <p>
                If you have any questions (or comments) concerning these Terms,
                you are most welcomed to contact us at{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="https://www.astrohouz.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.astrohouz.com ,
                </a>{" "}
                and we will make an effort to reply within a reasonable
                time-frame.
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>23. OTHER POLICIES</h5>
            <div className="leftDiv">
              <p>
                The subsequent policies and regulations are integrated into this
                document by reference, adding supplementary terms and
                conditions:
                <ul>
                  <li>Privacy Policy</li>
                  <li>Pricing Policy</li>
                  <li>Refund and Cancellation Policy</li>
                  <li>Cookie Policy</li>
                  <li>Disclaimer</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
