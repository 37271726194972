// Header.js
import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../assets/image/Final-01 1.png";
import { useLocation, useNavigate } from "react-router-dom";
import "../Header/HeaderMain.css";

function Header() {
  const navigate = useNavigate();
  let location = useLocation();

  const handleNavigateHome = () => {
    navigate("/");
  };

  const handleNavigateTerms = () => {
    navigate("/termsCondition");
  };

  const handleNavigatePolicy = () => {
    navigate("/privacyPolicy");
  };

  const handleNavigateAbout = () => {
    navigate("/aboutUs");
  };

  const handleNavigateWeWorks = () => {
    navigate("/weWorks");
  };

  return (
    <header style={{ fontFamily: "Caros" }}>
      <>
        {["md"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            style={{ backgroundColor: "rgba(246, 241, 248, 1)" }}
            className=" w-100 position-fixed shadow-sm"
            // position-fixed
          >
            <>
              <Navbar.Brand
                className="navPaddingLeft"
                onClick={handleNavigateHome}
              >
                <img src={logo} width={130} className="p-0 m-0" alt="" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                className="navPaddingRight"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    AstroHouz
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 ">
                    <Nav.Link
                      className={
                        location.pathname === "/"
                          ? "fw-bold allChanges mx-2 p-1 m-0"
                          : "fw-bold mx-2 p-1 m-0"
                      }
                      onClick={handleNavigateHome}
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      className={
                        location.pathname === "/termsCondition"
                          ? "fw-bold allChanges  mx-2 p-1 m-0"
                          : "fw-bold mx-2 p-1 m-0"
                      }
                      onClick={handleNavigateTerms}
                    >
                      Terms of Condition
                    </Nav.Link>
                    <Nav.Link
                      className={
                        location.pathname === "/privacyPolicy"
                          ? "fw-bold allChanges  mx-2 p-1 m-0"
                          : "fw-bold mx-2 p-1 m-0"
                      }
                      onClick={handleNavigatePolicy}
                    >
                      Privacy Policy
                    </Nav.Link>
                    {/* <Nav.Link
                      className={
                        location.pathname === "/aboutUs"
                          ? "fw-bold allChanges  mx-2 p-1 m-0"
                          : "fw-bold mx-2 p-1 m-0"
                      }
                      onClick={handleNavigateAbout}
                    >
                      About us
                    </Nav.Link>
                    <Nav.Link
                      className={
                        location.pathname === "/weWorks"
                          ? "fw-bold allChanges  mx-2 p-1 m-0"
                          : "fw-bold mx-2 p-1 m-0"
                      }
                      onClick={handleNavigateWeWorks}
                    >
                      How We Work
                    </Nav.Link> */}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </>
          </Navbar>
        ))}
      </>
    </header>
  );
}

export default Header;
