import "./App.css";
import Router from "./Router/Router";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/fonst/fonts.css";

function App() {
  return (
    <div>
        <Router />
    </div>
  );
}

export default App;
