import React from "react";
import Header from "../Header/Header";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import "../TermsCondition/TermsCondition.css";
import "../assets/fonst/fonts.css";

export default function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <Container style={{ fontFamily: "Caros" }} className="containt">
        <div className="TermsCondition">
          <h3 className="border-bottom pb-2 text-center mb-3">
            Privacy Policy
          </h3>
          <div>
            <div>
              <h5 className="py-1">
                Effective Date: ___01_ day of ___May_ 2024
              </h5>
              <h5 className="py-1">PLEASE READ THE FOLLOWING CAREFULLY</h5>
              <h6 className="py-1 fw-bolder">
                THIS STATEMENT PROVIDES GENERAL INFORMATION ABOUT THE PRIVACY
                STATEMENT OF THIS WEBSITE AND APP (MOBILE OR OTHERWISE). IF YOU
                ARE UNDER 18 YEARS OF AGE, PLEASE BE SURE TO READ THIS PRIVACY
                STATEMENT WITH YOUR PARENTS OR GUARDIAN AND ASK THEM QUESTIONS
                ABOUT WHAT YOU DO NOT UNDERSTAND.
              </h6>
              <h6 className="py-1 fw-bolder">
                YOUR USE OF THIS SERVICE CONSTITUTES ACCEPTANCE BY YOU OF THIS
                PRIVACY STATEMENT.
              </h6>

              <p>
                <strong>
                  HOUZ OF MEDIA LLC (hereinafter referred to as ASTROHOUZ)
                </strong>
                , operator of the ASTROHOUZ App and,{" "}
                <a
                  style={{ textDecoration: "none" }}
                  href="https://www.astrohouz.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.astrohouz.com
                </a>{" "}
                (collectively, the site, website, app or ASTROHOUZ App) is
                dedicated to protect our users' (“users” or “you”) privacy
                rights. The purpose of this page is to inform you about our
                policies with the collection, use, and disclosure of Personally
                Identifiable Information (PII) if anyone decides to use the
                applications or software offered by us (collectively, the
                “Service”).
              </p>

              <p>
                We take your privacy and data security seriously. This privacy
                policy ("Privacy Policy”) is meant to help you understand what
                information <strong>ASTROHOUZ</strong> collects, how we use such
                information, and the recipients with whom we share such
                information.
              </p>
              <p>
                Thus, <strong>ASTROHOUZ</strong> has created this privacy
                statement (“Statement”) in order to demonstrate its firm
                commitment to the privacy of the details that you provide to us
                when using the ASTROHOUZ Application or site, as the data
                controller for the purposes of –{" "}
                <strong>
                  The California Consumer Protection Act (CCPA), the California
                  Online Privacy Protection Act (CALOPPA), and the GDPR (GENERAL
                  DATA PROTECTION REGULATIONS EU 2016/679).
                </strong>
              </p>
              <p>
                This Privacy Policy applies to your access and use of the
                website, and mobile applications named ASTROHOUZ APP made
                available through the Apple App Store and the Google Play Store
                along with any revisions, updates and/or modifications thereto
                (collectively, “App”) and any data, products, services and
                associated materials or media supplied with the App (together
                with the App, the “Services”).
              </p>
              <p>
                In this Privacy Policy, we’ve provided detailed information on
                when and why we collect personal information, how we use it, the
                limited conditions under which we may disclose it to others, and
                how we keep it secure.
              </p>
              <p>
                We take your privacy seriously and take measures to provide all
                visitors and users of the website with a safe and secure
                environment.
              </p>
              <p>
                The Personal Information on the site, is collected, controlled,
                and processed by the data controller, collected on behalf of;{" "}
                <br />
                <b> HOUZ OF MEDIA LLC.</b>
                <br />
                <b>California,</b>
                <br />
                <b> United States</b>
                <br />
                <b> EMAIL : </b>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:privacy@houzofmedia.com"
                >
                  privacy@houzofmedia.com
                </a>
              </p>
              <p>This Privacy Policy contains the following sections:</p>
              <ol>
                <li>What Information We Collect.</li>
                <li>Use Of Collected Information.</li>
                <li>Sharing Of Information.</li>
                <li>How Long Do We Keep Your Information.</li>
                <li>Social sharing features.</li>
                <li>International Transfers.</li>
                <li>Security Of Your Information.</li>
                <li>Advertising and Analytics Services Provided by Others.</li>
                <li>Children’s Privacy.</li>
                <li>Choices and Rights To Your Data.</li>
                <li>Changes to this Privacy Policy. </li>
                <li>Governing Law and Disputes.</li>
                <li>Contact Us.</li>
              </ol>
            </div>

            <div className="mt-3">
              <h5>01. WHAT INFORMATION WE COLLECT</h5>
              <div className="leftDiv">
                <div>
                  <h6>Information You Provide to Us</h6>
                  <ul className="leftDiv">
                    <li>
                      <b>
                        {" "}
                        The personal information we may collect from you
                        includes:
                      </b>
                    </li>
                    <ul>
                      <li className="mt-1">
                        <b>Identifying Information :</b> Your name, phone
                        number, and email address are essential for creating
                        your account and communicating with you regarding
                        services.
                      </li>
                      <li>
                        <b>Birth Details :</b> Date of birth, time of birth,
                        city of birth, state of birth, and country of birth are
                        collected to provide personalized astrological and
                        ritual services tailored to your unique profile.
                      </li>
                      <li>
                        <b>Physical Address :</b> Your current physical address
                        is necessary for arranging in-home services, ensuring
                        that our specialists reach the correct location to
                        perform the services you request.
                      </li>
                      <li>
                        <b>Booking For Family and Friends :</b> When you book a
                        service using your account for a family and friends then
                        we will collect their data provided by you.
                      </li>
                    </ul>
                    <li className="mt-4">
                      <b>
                        {" "}
                        In addition to the above, when you complete transactions
                        via our site, we collect:
                      </b>
                      <ul>
                        <li className="mt-1">
                          <b>Purchasing Data :</b> Information related to any
                          transactions you conduct on our website, including
                          purchases of services or credits for use within
                          ASTROHOUZ. This data helps us process transactions
                          efficiently and to maintain accurate financial
                          records.
                        </li>
                      </ul>
                    </li>
                    <li className="mt-3">
                      <b>
                        {" "}
                        We also collect information when you interact with our
                        media blog through:
                      </b>
                      <ul>
                        <li className="mt-1">
                          <b>Feedback and Contributions :</b> Reviews, comments,
                          and responses to surveys, which help us to improve our
                          services and understand user satisfaction.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div>
                  <h6>Information We Collect Automatically</h6>
                  <ul className="leftDiv">
                    <li>
                      <b>
                        When you access or use the Services, the types of
                        information we may automatically collect about you
                        include:
                      </b>
                    </li>
                    <ul>
                      <li className="mt-1">
                        <b>Log Information :</b>When you visit the Services, our
                        servers automatically record certain log file
                        information, such as your Internet Protocol (“IP”)
                        address, operating system, browser type and language,
                        referring URLs, access times, pages viewed, links
                        clicked, other information about your activities on the
                        Services, and browsing and/or search history associated
                        with the websites you explore before or after visiting
                        our Sites. 
                      </li>
                      <li className="mt-1">
                        <b>Mobile Device Information :</b> We collect
                        information about the mobile device you use to access or
                        use the Services, including the hardware model,
                        operating system and version, unique device identifiers,
                        mobile network information and information about your
                        use of the Services. With your consent, we may also
                        collect information about the location of your device
                        and access and collect information from certain native
                        applications on your device (such as your device’s
                        camera, photo album and phonebook applications) to
                        facilitate your use of certain features of the Services.
                        For more information about how you can control the
                        collection of location information and/or our access to
                        other applications on your device, please see “Your
                        Choices” below.
                      </li>
                      <li className="mt-1">
                        <b>
                          Information Collected by Cookies and Other Tracking
                          Technologies :
                        </b>{" "}
                        We and our service providers use various tracking
                        technologies, including cookies and web beacons, to
                        collect information about you when you interact with our
                        Services, including information about your browsing and
                        purchasing behavior.Cookies are small data files stored
                        on your hard drive or in device memory that help us
                        improve the Services and your experience, see which
                        areas and features of the Services are popular, and
                        count visits. Web beacons are electronic images that may
                        be used in the Services or emails and help deliver
                        cookies, count visits and understand usage and
                        effectiveness. For more information about cookies, and
                        how to disable them, please see{" "}
                        <u>
                          <b>“Your Choices”</b>
                        </u>{" "}
                        below.
                      </li>
                      <li>
                        Even when the application is closed or inactive, we use
                        background location detection, but only if you click the
                        "Start Tracking" button. This is essential in order to
                        allow mileage monitoring for tax purposes, work
                        proximity notifications, automated job status changes,
                        and precise time tracking on job sites. Location
                        information, including the city, state and zip code
                        associated with your IP Address, information derived
                        through Wi-Fi triangulation, and precise location
                        information from GPS-based functionality on your mobile
                        apps, with your consent. Our use of such location
                        information is done with Google’s review best practices
                        for accessing location. For more information, visit –{" "}
                        <a
                          style={{
                            textDecoration: "none",
                            wordBreak: "break-word",
                          }}
                          href="https://support.google.com/googleplay/android-developer/answer/9799150?hl=en#zippy=%2Cstep-review-best-practices-for-accessing-location"
                        >
                           https://support.google.com/googleplay/android-developer/answer/9799150?hl=en#zippy=%2Cstep-review-best-practices-for-accessing-location.
                        </a>
                      </li>
                    </ul>
                  </ul>
                </div>
                <div>
                  <h6>Information We Collect from Other Sources</h6>
                  <p className="leftDiv">
                    We may also obtain information about you from other sources
                    and combine that with information we collect directly. For
                    example, we may collect information about you when you post
                    content to our pages or feeds on third party social media
                    sites, or if you use credentials (e.g., username and
                    password) from a third-party site to create or log into your
                    account.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <h5>02. USE OF COLLECTED INFORMATION</h5>
              <div className="leftDiv">
                <div>
                  <h6>
                    We may use information about you for various purposes,
                    including to
                  </h6>
                  <ul className="leftDiv">
                    <li>Provide, maintain, and improve the Services.</li>
                    <li>
                      Manage your account and send you related information,
                      including confirmations, updates, technical notices,
                      security alerts and support and administrative messages
                    </li>
                    <li>
                      Respond to your comments, questions and requests and
                      provide customer service.
                    </li>
                    <li>
                      Communicate with you about the Services, offers, surveys,
                      events and other news and information we think may be of
                      interest to you.
                    </li>
                    <li>
                      Monitor and analyze trends, usage, and activities in
                      connection with the Services.
                    </li>
                    <li>
                      Detect, investigate, and prevent fraudulent transactions
                      and other illegal activities and protect our and others’
                      rights and property.
                    </li>
                    <li>
                      Personalize and improve the Services and provide
                      advertisements, content and features that match your
                      profile or interests.
                    </li>
                    <li>
                      Link or combine with information we get from others to
                      help understand your needs and provide you with a better
                      experience and tailored promotions. 
                    </li>
                    <li>
                      Carry out any other purpose for which the information was
                      collected like verifying your identity, deliver services
                      to you, process orders, handle payment for any purchase
                      made.
                    </li>
                    <li>
                      Enforce all our policies and terms of use and investigate
                      any violations of these policies.
                    </li>
                    <li>
                      Aggregate your information collected through our platforms
                      and from third parties to create customer profiles, aiding
                      in tailored communication and platform improvement. 
                    </li>
                    <li>
                      Analyze and anonymize data for research purposes, as well
                      as for the development of new tools, features, products,
                      and services. We may securely share anonymized data with
                      third parties.
                    </li>
                    <li>
                      For users in the USA (excluding the Commonwealth of
                      Virginia, States of Colorado, and Connecticut), we may use
                      your personal data to train artificial intelligence
                      models. We will refrain from using personal data of users
                      located in the EU, UK, Virginia, Colorado, Connecticut, or
                      any other jurisdiction where such use is not permitted
                      under applicable law for these purposes
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <h5>03. SHARING OF INFORMATION</h5>
              <div className="leftDiv">
                <div>
                  <h6 className="mt-1">
                    We may share information about you as follows or as
                    otherwise described in this Privacy Policy:
                  </h6>
                  <ul className="leftDiv">
                    <li>
                      In response to a request for information if we believe
                      disclosure is in accordance with, or required by, any
                      applicable law, rule, regulation, or legal process.
                    </li>
                    <li>
                      If we believe your actions are inconsistent with our Terms
                      of Use or other policies, or to protect our and other
                      parties’ rights, property, or safety.
                    </li>
                    <li>
                      In connection with, or during negotiations of, any merger,
                      acquisition, sale of assets or any business, other change
                      of control transaction or financing.
                    </li>
                    <li>
                      Between and among us and any current or future parent,
                      subsidiary and/or affiliated company; and
                    </li>
                    <li>With your consent or at your direction.</li>
                  </ul>
                </div>

                <div>
                  <h6 className="mt-1">
                    We also may share aggregated or de-identified information,
                    which cannot reasonably be used to identify you.
                  </h6>
                </div>

                <div>
                  <h6 className="mt-1">
                    We might share or disclose information about you to third
                    parties under the following circumstances:
                  </h6>
                  <ul className="leftDiv">
                    <li>
                      In facilitating the platform for our users, we may reveal
                      specific details about you, such as your name and
                      username, to other platform users, solely as outlined by
                      you, when you leave reviews for our specialists.
                    </li>
                    <li>
                      We also reveal information collected about you to our
                      third-party service providers and other partners who
                      conduct services on our behalf in connection with any of
                      the Platforms. The services rendered by such third parties
                      and partners encompass activities in the following
                      categories:
                      <ol>
                        <li>Processing payments on our behalf.</li>
                        <li>
                          Assisting us in providing products or services that
                          you request.
                        </li>
                        <li>Sending marketing communications on our behalf.</li>
                        <li>Authenticating identities on our behalf.</li>
                        <li>
                          Aiding us in creating or maintaining our databases.
                        </li>
                        <li>
                          Assisting us in researching or analyzing the people
                          who use our Platforms.
                        </li>
                        <li>Testing our Platforms.</li>
                      </ol>
                      Unless you provide consent, we do not authorize our
                      service providers to utilize such information for the
                      purpose of directly marketing their own products or
                      services to you. When we transfer any of your personally
                      identifiable information to such a service provider or
                      partner, that entity will be contractually obligated,
                      among other things to use such information solely to
                      fulfill the services we have requested, adhere to this
                      Privacy Policy, furnish adequate protection for such
                      information, as mandated by applicable legal requirements.
                    </li>
                    <li>
                      Some of our Platforms may enable you to connect with
                      and/or post content to social networking sites, You
                      understand and agree that you bear full responsibility for
                      your utilization of any social networking sites. It's
                      incumbent upon you to examine the terms of use and privacy
                      policy of the third-party provider for such social
                      networking sites. We hold no responsibility or liability
                      for, the accessibility or correctness of any social
                      networking sites; the content, products, or services
                      offered on or the accessibility of any social networking
                      sites; or your utilization of any social networking sites.
                    </li>
                  </ul>
                </div>

                <div>
                  <h6 className="mt-1">
                    We refrain from disclosing your personal data to
                    unaffiliated third parties for their independent direct
                    marketing endeavors without your explicit consent.
                  </h6>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <h5>04. HOW LONG DO WE KEEP YOUR INFORMATION.</h5>
              <div className="leftDiv">
                <p>
                  We will keep your information as long as your account remains
                  active or as required to deliver services to you. Please be
                  aware that we may, however, keep and utilize your information
                  as necessary to settle outstanding account balances, meet our
                  legal obligations, resolve disputes, and enforce our
                  agreements, or if it is not technically and reasonably
                  feasible to remove such information from our databases. This
                  implies that we may retain and utilize your personal data as
                  needed even after you cease using our Platforms.
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h5>05. SOCIAL SHARING FEATURES</h5>
              <div className="leftDiv">
                <p>
                  The Services may offer social sharing features and other
                  integrated tools, such as the Facebook and Twitter buttons
                  (e.g., “Like” or “Tweet”), which let you share actions you
                  take on the Services with other media. Your use of such
                  features enables the sharing of information with your friends
                  or the public, depending on the settings you establish with
                  the entity that provides the social sharing feature. For more
                  information about the purpose and scope of data collection and
                  processing in connection with social sharing features, please
                  visit the privacy policies of the entities that provide these
                  features.
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h5>06. INTERNATIONAL TRANSFER</h5>
              <div className="leftDiv">
                <p className="mt-2">
                  As a global business, Houz of Media LLC and its service
                  providers may handle, transfer, and store user information on
                  servers situated in various countries outside the European
                  Economic Area (EEA), including the United States. Because
                  safeguarding your information is our priority, we implement
                  measures to guarantee that appropriate safeguards are in place
                  whenever we transfer such data.
                </p>
                <p className="mt-2">
                  <b> Extra disclosures for users residing in the EU and UK:</b>{" "}
                  By utilizing any of our Platforms from outside the United
                  States, you acknowledge that your personal data may be
                  accessed by us or transferred to us in the United States and
                  to our affiliates, partners, and service providers located
                  worldwide. Your personal data will be transferred to, stored,
                  and processed in the United States or elsewhere in the world
                  where our servers are situated. If your personal data is
                  transferred to a country that does not provide an adequate
                  level of protection for personal data compared to the GDPR, we
                  will ensure such transfer is governed by an agreement that
                  includes standard contractual clauses approved for this
                  purpose by the relevant body in the EU and/or UK. This ensures
                  your personal data remains protected.
                </p>
                <p className="mt-2">
                  If you are based in the EU or UK, we will only process your
                  personal data for purposes described in this Privacy Policy
                  under the following conditions:
                </p>
                <ul>
                  <li>
                    You have provided your consent (which can be withdrawn at
                    any time). 
                  </li>
                  <li>
                    The processing is necessary for the performance of a
                    contract we are about to enter into or have entered into
                    with you. 
                  </li>
                  <li>We are required by law. </li>
                  <li>
                    Processing is required to protect your vital interests or
                    those of another person. 
                  </li>
                  <li>
                    The processing is necessary for the purposes of our
                    legitimate commercial interests (except where such interests
                    are overridden by your rights and interests). Our legitimate
                    interests generally include operating the Platform and
                    providing services to you via the Platform. We may also
                    process your personal data to further develop and improve
                    the Services, analyze traffic to better serve all Service
                    users, analyze user preferences, and provide a more stable,
                    consistent, and secure user experience.
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-3">
              <h5>07. SECURITY OF YOUR INFORMATION</h5>
              <div className="leftDiv">
                <p className="mt-2">
                  The security of your information is important to us. We
                  implement and maintain reasonable security measures intended
                  to protect against unauthorized use, disclosure, alteration,
                  or destruction of the information we collect and maintain.
                  However, please be aware that, despite our efforts, no data
                  security measures, no method of transmission over the
                  Internet, and no means of electronic or physical storage, is
                  absolutely secure.{" "}
                  <b>
                    {" "}
                    AS SUCH, YOU ACKNOWLEDGE AND ACCEPT THAT WE CANNOT GUARANTEE
                    THE ABSOLUTE SECURITY OF YOUR INFORMATION TRANSMITTED TO,
                    THROUGH, OR ON THE SERVICES OR VIA THE INTERNET AND THAT,
                    ANY SUCH TRANSMISSION IS AT YOUR OWN RISK.
                  </b>
                  <br />
                  <p className="mt-2">
                    {" "}
                    WHERE WE HAVE GIVEN YOU (OR WHERE YOU HAVE CHOSEN) A
                    PASSWORD WHICH ENABLES YOU TO ACCESS TO YOUR ACCOUNT, YOU
                    ARE RESPONSIBLE FOR KEEPING THIS PASSWORD CONFIDENTIAL.
                    PLEASE DO NOT TO SHARE A PASSWORD WITH ANYONE.
                  </p>
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h5>08. ADVERTISING AND ANALYTICS SERVICES PROVIDED BY OTHERS</h5>
              <div className="leftDiv">
                <p>
                  We may allow others to serve advertisements on our behalf
                  across the Internet and to provide analytics services. We use
                  Google Analytics and Google Crashlytics. With your consent,
                  the mobile access app uses the analysis tools Google Analytics
                  for Firebase and Firebase Crashlytics (collectively “Google
                  Analysis Tools”) from Google LLC as described in more detail
                  below, to analyze user behavior and to report on the stability
                  and improvement of the mobile access app.
                </p>
                <p>
                  Such tools are used on the basis of your consent (Art. 6 para.
                  1 lit. a GDPR): When you start the mobile access app for the
                  first time, you can decide whether you do or do not wish to
                  consent to the use of one or both Google analysis tools. After
                  starting for the first time, you can withdraw your consent(s),
                  where given, at any time by disabling the corresponding Google
                  analysis tool in the settings of the mobile access app. The
                  Google analysis tools can also be (re-)enabled at any time in
                  these settings.
                </p>
                <p>
                  More information about both Google Analytics tools may be
                  found at:{" "}
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://firebase.google.com/support/privacy"
                  >
                    https://firebase.google.com/support/privacy
                  </a>
                  as well as in{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://policies.google.com/privacy"
                  >
                     Google’s privacy policy.
                  </a>
                </p>
                <p>
                  Enabling Google Analytics for Firebase permits data about the
                  user of the mobile access App to be collected for the purposes
                  of analysis.
                </p>
                <p>
                  Through Google Analytics for Firebase, Google collects data on
                  the device (in particular the Mobile Advertising Identifier),
                  IP addresses and app activities on our behalf to evaluate the
                  use of the mobile access app, to compile reports on the
                  activities performed within the mobile access app and to
                  permit it to offer us other services related to the use of the
                  mobile access app.
                </p>
                <p>
                  In addition to disabling Google Analytics for Firebase in the
                  settings of the mobile access app, you may limit the use of
                  the above-mentioned Google Play Services Advertising ID
                  (Android) or IDFA (iOS) in the device settings of your mobile
                  device:
                </p>
                <p>
                  For Android: Settings Google Ads Disable personalized
                  advertising.
                </p>
                <p>For iOS: Settings Privacy Advertising Limit Ad Tracking</p>
                <p>
                  You can find more information on data protection, in
                  particular on the personal data involved in specific cases,
                  at:{" "}
                  <a
                    style={{ textDecoration: "none", wordBreak: "break-word" }}
                    href=" https://support.google.com/firebase/answer/6318039"
                  >
                    {" "}
                    https://support.google.com/firebase/answer/6318039.
                  </a>
                </p>
                <p>The personal data will be deleted after 14 months.</p>
                <p>
                  We also use the Google analysis tool Firebase Crashlytics to
                  receive error reports following any crash of the mobile access
                  app and to measure and improve the performance of the app. If
                  the mobile access app crashes, information about the crash,
                  such as device type, operating system version and some
                  technical data, is sent to Google via the mobile device, which
                  enables us to diagnose and solve problems (so-called crash
                  reports). The data is stored anonymously.
                </p>
                <p>
                  More information about Firebase Crashlytics can be found at:{" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://firebase.google.com/docs/crashlytics"
                  >
                    https://firebase.google.com/docs/crashlytics
                  </a>
                </p>
                <p>
                  With regards to personalized ads, If you want to stop
                  receiving personalized ads, you can go to “Settings”, once you
                  choose not to receive personalized ads, we and our third-party
                  partners will stop sending personalized ads to you. Please
                  note that the number of ads you see will not change, but they
                  may be less relevant to you.
                </p>
                <p>
                  Our platforms may provide options for engaging with social
                  plugins, such as Facebook's Like and Share buttons, as well as
                  other widgets and interactive mini programs. These
                  functionalities might gather your IP address, the specific
                  page you're viewing on our platforms and could employ cookies
                  to ensure smooth operation. Social media features and widgets
                  may be hosted by third parties or directly on our platforms.
                  Similar to linked sites, we lack control over the data
                  collected, stored, or utilized by social network plugins, and
                  we bear no responsibility for their practices. Furthermore, if
                  you opt to connect with us or utilize certain social media
                  features, we may receive pertinent information about you from
                  third-party social networks, contingent upon your registration
                  and privacy settings within those services.
                </p>
                <p>
                  You understand and agree that you bear full responsibility for
                  your utilization of any social networking sites. It's
                  incumbent upon you to examine the terms of use and privacy
                  policy of the third-party provider for such social networking
                  sites. We hold no responsibility or liability for, the
                  accessibility or correctness of any social networking sites;
                  the content, products, or services offered on or the
                  accessibility of any social networking sites; or your
                  utilization of any social networking sites.
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h5>09. CHILDREN’S PRIVACY</h5>
              <div className="leftDiv">
                <p>
                  The Services are not intended for children under the age of
                  18. Children under the age of 18 must not use the Services for
                  any purpose without first obtaining legally valid
                  parental/guardian consent to this Privacy Policy (both for
                  themselves and on your behalf).{" "}
                  <b>
                    {" "}
                    WE DO NOT KNOWINGLY COLLECT PERSONAL INFORMATION FROM ANY
                    CHILDREN UNDER THE AGE OF 18 WITHOUT SUCH CONSENT. PLEASE
                    CONTACT US IF YOU BELIEVE WE HAVE ANY PERSONAL INFORMATION
                    FROM ANY CHILDREN UNDER THE AGE OF 18 WITHOUT SUCH
                    PARENTAL/GUARDIAN CONSENT. WE WILL TAKE REASONABLE STEPS TO
                    DELETE IT AS SOON AS POSSIBLE.
                  </b>
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h5>10. CHOICES AND RIGHTS TO YOUR DATA</h5>
              <div className="leftDiv">
                <div>
                  <h6>YOUR CHOICES</h6>
                  <ul className="leftDiv">
                    <li>
                      <b> Account Information</b>
                      <br />
                      <p>
                        You may update, correct, or modify information about you
                        at any time by logging into your online account or by
                        emailing us at{" "}
                        <a
                          style={{ textDecoration: "none" }}
                          href="mailto:privacy@houzofmedia.com"
                        >
                          privacy@houzofmedia.com
                        </a>
                        .  If you wish to deactivate your account, please email
                        us at{" "}
                        <a
                          style={{ textDecoration: "none" }}
                          href="mailto:rivacy@houzofmedia.com "
                        >
                          privacy@houzofmedia.com 
                        </a>{" "}
                        but note we may continue to store information about you
                        as required by law or for legitimate business purposes.
                      </p>
                    </li>

                    <li>
                      <b>Location Information</b>
                      <br />
                      <p>
                        With your consent, we may collect information about your
                        actual location when you use our mobile applications.
                        You may stop the collection of this information at any
                        time by changing the settings on your mobile device but
                        note that some features of our mobile applications may
                        no longer function if you do so.
                      </p>
                    </li>

                    <li>
                      <b>Native Applications on Mobile Device</b>
                      <br />
                      <p>
                        Some features of our mobile applications may require
                        access to certain native applications on your mobile
                        device, such as the camera and photo storage
                        applications (e.g., to take and upload photos) and the
                        phonebook application (e.g., to send invitations to your
                        contacts to try the Services). If you decide to use
                        these features, we will ask you for your consent prior
                        to accessing the applications and collecting
                        information. Note that you can revoke your consent at
                        any time by changing the settings on your device.
                      </p>
                    </li>

                    <li>
                      <b>Cookies</b>
                      <br />
                      <p>
                        Most web browsers are set to accept cookies by default.
                        If you prefer, you can usually choose to set your
                        browser to remove or reject browser cookies. Please note
                        that if you choose to remove or reject cookies, this
                        could affect the availability and functionality of the
                        Services.
                      </p>
                      <p>
                        Cookies are small files that we or others send to and
                        store on or with your computer so that your computer,
                        browser, mobile app, or other application can be
                        recognized as unique the next time you access, visit,
                        use or otherwise take advantage of the Services or other
                        media. Cookies may also reflect demographic data
                        pertaining to you or other data linked to information
                        you submit. One use or consequence of cookies is to
                        enable you to receive customized ads, alerts, content,
                        services, or information. You are always free to decline
                        any cookies we use by adjusting the settings of your
                        browser, as your browser may permit; however, some
                        products, services or features might not be available or
                        operate properly if cookies are not enabled. Some of our
                        advertisers and third-party service providers may also
                        utilize their own cookies.
                      </p>
                      <p>
                        In addition, we, our service providers, and others
                        sometimes use data-gathering mechanisms on the Services,
                        including without limitation “web beacons”, “clear
                        GIFs”, “pixels” and/or “tags”. These perform statistical
                        and administrative functions, such as measuring site and
                        page traffic, verifying advertising paths, better
                        understanding user interests and activity, gathering
                        related information (such as information relating to a
                        particular browser, device, or IP address) and
                        positioning images, and typically do so without
                        detracting from your online experience. Such mechanisms
                        are not necessarily designed to collect Personal
                        Information. In addition, if you have provided your
                        email address, we might use a non-human unreadable form
                        (or “hash”) of your email address to deliver, or
                        facilitate delivery of, relevant advertisements and
                        information to you on or by way of the Services or on or
                        by way of other websites or media, including, for
                        example, popular social media sites and features.
                      </p>
                    </li>

                    <li>
                      <b>Promotional Communications</b>
                      <br />
                      <p>
                        You may opt out of receiving promotional communications
                        from us by following the instructions in those
                        communications or by emailing us at
                        privacy@houzofmedia.com.  If you opt out, we may still
                        send you non-promotional communications, such as those
                        about your account or our ongoing business relations.
                      </p>
                    </li>

                    <li>
                      <b>Push Notifications</b>
                      <br />
                      <p>
                        With your consent, we may send promotional and
                        non-promotional push notifications or alerts to your
                        mobile device. You can deactivate these messages at any
                        time by changing the notification settings on your
                        mobile device.
                      </p>
                    </li>

                    <li>
                      <b>Unsubscribe preferences:</b>
                      <br />
                      <p>
                        You can access and adjust your preferences for receiving
                        subscriptions (such as horoscopes and newsletters),
                        promotional communications, and alerts anytime by
                        visiting Mail Preferences. Additionally, you have the
                        option to cease receiving such communications delivered
                        via email by following the unsubscribe instructions
                        provided in the emails. Please note that even if you
                        unsubscribe from certain emails, we may still send you
                        messages concerning transactions you've initiated or
                        other crucial non-marketing communications.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <h6>YOUR RIGHTS</h6>
                  <p>
                    All Your Personal Information we collect will always belong
                    to you. However, we are a collector and a processor of Your
                    Personal Information. That implies on us obligations to
                    respect your rights to Personal Information and facilitate
                    the exercise of your rights thereto. In order to use any of
                    your rights at any time please contact us and we will
                    facilitate the exercise of your rights free of charge. We
                    will inform you on the actions taken by us under your
                    request as soon as practically possible, but in any case,
                    not later than in 30 (thirty) calendar days.
                  </p>
                  <p>
                    In accordance with effective regulations and rights outlined
                    elsewhere in this Privacy Policy, you have a significant
                    number of rights related to your Personal Information, such
                    as e.g.:
                  </p>
                  <ul className="leftDiv">
                    <li>
                      <b>Right to Consent: </b> Individuals have the right to
                      provide or withhold their consent for the collection, use,
                      or disclosure of their personal information by
                      organizations. Organizations must obtain clear and
                      informed consent before collecting or using personal
                      information, and individuals can withdraw their consent at
                      any time.
                    </li>
                    <li>
                      <b>Access to Personal Information: </b> Individuals have
                      the right to request access to their personal information
                      held by an organization. Organizations must provide
                      individuals with access to their personal information
                      within a reasonable timeframe and at a reasonable cost.
                    </li>
                    <li>
                      <b>Correction of Personal Information:</b> If an
                      individual believes that their personal information held
                      by an organization is inaccurate or incomplete, they have
                      the right to request that the information be corrected.
                      Organizations must make the necessary corrections and, if
                      applicable, inform third parties of the corrections.
                    </li>
                    <li>
                      <b>Notification of Data Breaches:</b>Organizations are
                      required to notify individuals if there is a data breach
                      that poses a real risk of significant harm to them. The
                      notification must include information about the breach and
                      steps that individuals can take to mitigate potential
                      harm.
                    </li>
                    <li>
                      <b>Limiting Collection, Use, and Disclosure:</b>
                      Organizations are required to limit the collection, use,
                      and disclosure of personal information to purposes that
                      are reasonable and necessary. Personal information should
                      only be used for the purposes for which it was collected
                      unless the individual provides consent or as required by
                      law.
                    </li>
                    <li>
                      <b>Safeguarding Personal Information:</b>Organizations are
                      obligated to take reasonable measures to safeguard the
                      personal information they collect, including the
                      implementation of security safeguards to protect against
                      unauthorized access, disclosure, copying, use, or
                      modification.
                    </li>
                    <li>
                      <b>Retention and Disposal:</b>Personal information should
                      be retained only for as long as necessary to fulfill the
                      purposes for which it was collected. Once it is no longer
                      required, it should be securely disposed of.
                    </li>
                    <li>
                      <b>Accountability and Transparency:</b>Organizations are
                      responsible for ensuring compliance with effective
                      regulations. They must designate individuals who are
                      accountable for privacy matters and make their privacy
                      policies and practices readily available to the public.
                    </li>
                    <li>
                      <b>
                        Right to Opt-Out of the Sale or Sharing of Personal
                        Information:
                      </b>
                      Certain types of data collection and processing by us for
                      personalized advertising or analytics may be considered a
                      "sale" or "sharing" under CCPA. For example, we may be
                      selling or sharing certain data, which may include
                      personal information, to ad tech companies and affiliate
                      marketers. These parties may also further sell data to
                      others.
                    </li>
                    <p>
                      If you are a resident of Connecticut, Colorado, Utah, or
                      Virginia, you may also take advantage of certain privacy
                      rights, such as to request access, correction, deletion,
                      or a copy of your personal information. Additionally,
                      because we may "sell" personal information or engage in
                      "targeted advertising" as defined in Connecticut,
                      Colorado, Utah, or Virginia, you may exercise your right
                      to opt-out of such sales or targeted advertising. You also
                      have the right to appeal a denial of your privacy rights.
                    </p>
                  </ul>
                </div>

                <div>
                  <h6>Rights Under the GDPR:</h6>
                  <p>
                    If you are based in the EU or UK, you have certain rights
                    under data protection laws regarding your personal data that
                    we hold about you:
                  </p>
                  <ol className="leftDiv">
                    <li>Request access to your personal data.</li>
                    <li>Request correction of your personal data.</li>
                    <li>Request deletion of your personal data.</li>
                    <li>
                      Request restriction of processing your personal data.
                    </li>
                    <li>Request transfer of your personal data.</li>
                  </ol>
                  <p>
                    Additionally, if you believe that we have not complied with
                    our obligations under this Privacy Policy or European data
                    protection law, you have the right to make a complaint to an
                    EU or UK Data Protection Authority.
                  </p>
                  <p>
                    You can exercise any of these rights by emailing us at{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:privacy@houzofmedia.com"
                    >
                      privacy@houzofmedia.com
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <h5>11. CHANGES TO THIS PRIVACY POLICY</h5>
              <div className="leftDiv">
                <p>
                  We reserve the right to amend this Privacy Policy at any time.
                  You will know if the Privacy Policy has changed since the last
                  time, you reviewed it by checking the “EFFECTIVE DATE”
                  included at the beginning of the document. If we make a
                  material change to the Policy, you will be provided with
                  appropriate notice in accordance with legal requirements. By
                  continuing to use the Services after changes have been posted,
                  you are confirming that you have read, understood, and
                  consented to the latest version of this Privacy Policy.
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h5>12. GOVERNING LAW AND DISPUTES</h5>
              <div className="leftDiv">
                <p>
                  This Agreement will be governed by, construed, and enforced in
                  accordance with the laws of California, without regard to its
                  conflicts of law principles or provisions. California shall
                  have jurisdiction to adjudicate disputes.
                </p>
              </div>
            </div>

            <div className="mt-3">
              <h5>13. CONTACT US</h5>
              <div className="leftDiv">
                <p>
                  If you have any questions about this Privacy Policy, wish to
                  exercise any of your rights to your Personal Information or
                  file a complaint, please contact us at: <br />
                  <b> HOUZ OF MEDIA LLC.</b>
                  <br />
                  <b>California, USA </b>
                  <br />
                  <b>
                    EMAIL :{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:privacy@houzofmedia.com"
                    >
                      {" "}
                      privacy@houzofmedia.com
                    </a>
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
