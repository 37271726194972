import React from "react";
import Header from "../Header/Header";
import "../Home/Home.css";
import mainImage from "../assets/image/Group 2117594329.png";
import Footer from "../Footer/Footer";
import "atropos/css";
import Atropos from "atropos/react";

export default function Home() {
  return (
    <>
      <Header />
      <div className="bgImage">
        <div className="d-flex justify-content-center align-items-center">
          <Atropos shadow={false} highlight={false}>
            <img
              data-atropos-offset="5"
              src={mainImage}
              className="bg-child_img"
              alt=""
            />
          </Atropos>
        </div>
      </div>
      <Footer />
    </>
  );
}
