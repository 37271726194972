// Footer.js
import React from "react";
import "../Footer/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p style={{ fontSize: "16px" }} className="p-0 m-0 fw-bold py-1">
        Copyright &copy; 2024 Houz Of Media LLC. All Rights Reserved.
      </p>
      <p className="py-1" style={{ fontSize: "13px", opacity: "0.7" }}>
        (Solely for entertainment purposes. Restricted to individuals aged 18
        years and above)
      </p>
    </footer>
  );
}

export default Footer;
